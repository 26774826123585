.main-layout__app-logo {
  max-height: 65px;
}

.main-layout__main-logo-container {
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  height: 113px;
  margin: 0 12px;
}

.main-layout__background-texture {
  background-image: url('/src/assets/img/background.svg');
  background-attachment: fixed;
}

.main-layout__layer-wheat-right {
  position: fixed;
  bottom: 0;
  right: 100px;
  transition: all 1s ease;
}

.main-layout__content {
  position: relative;
}
