@import url(https://fonts.googleapis.com/css2?family=Raleway:wght@300;400;500;600;700&display=swap);
.auth-layout__background-texture {
  background-image: url(/static/media/background.9c250b8f.svg);
  background-attachment: fixed;
}

.main-layout__app-logo {
  max-height: 65px;
}

.main-layout__main-logo-container {
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  height: 113px;
  margin: 0 12px;
}

.main-layout__background-texture {
  background-image: url(/static/media/background.9c250b8f.svg);
  background-attachment: fixed;
}

.main-layout__layer-wheat-right {
  position: fixed;
  bottom: 0;
  right: 100px;
  transition: all 1s ease;
}

.main-layout__content {
  position: relative;
}

:root {
  --primary: #ffc078;
  --secondary: #dbdbdb;
  --element-background: #141414;
  --layout-background: #000;
}

h1 {
  font-size: 24px;
}

.ant-input-number,
.ant-picker {
  min-width: 100% !important;
}

.ant-menu-dark,
.ant-menu-light,
.ant-layout-sider-trigger,
.ant-layout-sider {
  background: transparent !important;
}

body[data-theme='light'] {
  --element-background: #fff;
  --layout-background: #f0f2f5;
}

body[data-theme='light'] .ant-layout-sider-trigger {
  color: inherit;
}

.ant-layout-header {
  background-color: transparent !important;
  z-index: 1;
}

.ant-breadcrumb > span:last-child {
  color: #ffc078 !important;
  color: var(--primary) !important;
}

