@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@300;400;500;600;700&display=swap');

:root {
  --primary: #ffc078;
  --secondary: #dbdbdb;
  --element-background: #141414;
  --layout-background: #000;
}

h1 {
  font-size: 24px;
}

.ant-input-number,
.ant-picker {
  min-width: 100% !important;
}

.ant-menu-dark,
.ant-menu-light,
.ant-layout-sider-trigger,
.ant-layout-sider {
  background: transparent !important;
}

body[data-theme='light'] {
  --element-background: #fff;
  --layout-background: #f0f2f5;
}

body[data-theme='light'] .ant-layout-sider-trigger {
  color: inherit;
}

.ant-layout-header {
  background-color: transparent !important;
  z-index: 1;
}

.ant-breadcrumb > span:last-child {
  color: var(--primary) !important;
}
